<template>
  <div class="w-full h-full">
    <loader :loading="loading" :backdrop="true" />
    <h3 class="bg-gray-300 py-2 px-8 font-bold">
      editing field:
      <span class="font-normal ml-2">{{current_field.partner_field_name}}</span>
    </h3>
    <div class="p-8 w-full">
      <div>
        <div class="w-full flex gap-4 grid grid-cols-4">
          <label class="flex flex-col font-bold">
            partner field name:
            <text-input
              id="partner_field_name"
              :disabled="true"
              placeholder="partner field name"
              v-model="current_field.partner_field_name"
            />
          </label> 
          <label class="flex flex-col font-bold">
            partner field label:
            <text-input
              id="partner_field_label"
              placeholder="partner field label"
              v-model="current_field.partner_field_label"
            />
          </label> 
          <label class="flex flex-col font-bold">
            document name:
            <dropdown
              :options="documents"
              :placeholder="'select partner document'"
              :multiple="true"
              v-model="current_field.documentRelation"
            ></dropdown>
          </label>
          <label class="flex flex-col font-bold">
            data type:
            <dropdown
              :options="data_types"
              :placeholder="'select partner document'"
              v-model="current_field.partner_field_data_type"
            ></dropdown>
          </label>
          <label class="flex-col font-bold">
            description:
            <text-input
              id="description"
              placeholder="field description"
              v-model="current_field.description"
              :multiline="true"
            />
          </label>
        </div>
      </div>
      <div class="mt-2">
        <label class="font-semibold text-l my-4">field translation:</label>
        <div class="w-full flex grid grid-cols-4 gap-4 mt-2">
          <label class="flex flex-col font-bold">
            quext field:
            <dropdown
              :options="quext_fields"
              :placeholder="'select quext field'"
              v-model="current_field.quext_field_name"
            ></dropdown>
          </label>
          <label class="flex flex-col font-bold">
            quext field type:
            <dropdown
              :options="data_types"
              :placeholder="'select partner document'"
              v-model="current_field.quext_field_data_type"
            ></dropdown>
          </label>
          <label class="flex-col font-bold">
            remove character regx:
            <text-input
              id="remove_char_regx"
              placeholder="remove character with regx"
              v-model="current_field.remove_char_regx"
            />
          </label>
          <label class="flex-col font-bold">
            field translation description:
            <text-input
              id="field_translation_description"
              placeholder="field description"
              v-model="current_field.field_translation_description"
              :multiline="true"
            />
          </label>
          <label class="flex-col font-bold">
            json layer:
            <text-input
              id="partner_json_layer"
              placeholder="field json layer"
              v-model="current_field.partner_json_layer"
              :multiline="true"
            />
        </label>
        </div>
      </div>
    </div>
    <!-- Add new partner -->
    <section class="w-full" v-if="isSelectDocOpen">
      <div class="z-50 bottom-0 left-0 fixed w-full bg-white pb-6">
        <h3 class="bg-gray-300 py-2 px-8">Please select the document from where you want to delete the partner field</h3>
        <div class="p-8 w-1/4">
          <div class="w-full flex flex-col justify-start">
            <dropdown
              :options="current_field.documentRelation"
              :placeholder="'select document'"
              v-model="selected_doc"
            ></dropdown>
          </div>
        </div>
        <div class="flex justify-between p2-8 px-8">
          <div class="">
            <button @click="isSelectDocOpen = false, selected_partner = null">cancel</button>
          </div>
          <button @click="handleDeleteFieldDoc" class="btn-primary btn-solid">
            delete
          </button>
        </div>
      </div>
    </section>
    <ModalFooter :footer="footer" :primary="save" :tertiary="cancel" />
  </div>
</template>
<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import { mapGetters } from 'vuex';
import NotifyMixin from "@/mixins/NotifyMixin";
import Loader from "@/components/ui/Loader";
import Dropdown from "@/components/ui/Dropdown";
import TextInput from '@/components/ui/TextInput';
import EventBus from '@/utils/EventBus.js';
import ModalFooter from "@/components/ui/modals/ModalFooter";
export default {
  mixins: [NotifyMixin, ModalNavigation],
  components: {
    Loader,
    Dropdown,
    TextInput,
    ModalFooter
  },
  computed: {
    ...mapGetters({
      getPartnerField: 'partner_documents/getPartnerField',
    }),
    data_types() {
      return [{
        key: 'string',
        value: 'string'
      },
      {
        key: 'numeric',
        value: 'numeric'
      },
      {
        key: 'date',
        value: 'date'
      },
      {
        key: 'boolean',
        value: 'boolean'
      }]
    }
  },
  data() {
    return {
      current_field: null,
      is_row_editing: false,
      loading: false,
      quext_fields: [],
      partner_documents: [],
      documents: [],
      selected_doc: null,
      isSelectDocOpen: false,
      footer: {
        tertiaryButton: "cancel",
        primaryButton: "update",
      },
    };
  },
  methods: {
    cancel() {
      this.$router.push({ name: "manage-partner-documents.index" });
    },
    async save() {
      await Promise.all([
        this.updateField(),
        this.current_field.remove_char_regx ? this.updateFieldTranslation() : this.saveFieldTranslation(),

      ])
      .then(() => this.$router.push({ name: "manage-partner-documents.index" }))
      .catch(error => this.notifyError(error.message));
    },
    handleDeleteFieldDoc() {
      EventBus.emit('show-modal-confirmation', {
        id: 'delete-partner-field-document',
        title: 'edit document',
        showConfirmation: true,
        confirmationMessage: `Are you sure you want to delete this field: ${this.current_field.partner_field_name} from this document ${this.selected_doc}?`,
        confirmationType: 'warning',
      })
    },
    onDeletefromDoc() {
      this.$leasingDataProvider.delete('partner_field', {
        partner_id:  this.current_field.partner_id,
        partner_field_name: this.current_field.partner_field_name,
      })
      .then(() => {
          this.notifySuccess("the partner field was sucessfully deleted.");
          this.cancel();
      })
      .catch(() => this.notifyError('Something went wrong, please try again later.'))
      .finally(() => this.loading = false);
    },
    updateField() {
      this.$leasingDataProvider.patch('partner_field', {
        partner_id: this.current_field.partner_id,
        partner_document_id: this.selected_doc,
        partner_field_name: this.current_field.partner_field_name,
        data: {
          partner_id: this.current_field.partner_id,
          partner_field_name: this.current_field.partner_field_name,
          partner_field_label: this.current_field.partner_field_label,
          partner_field_data_type: this.current_field.partner_field_data_type,
          description: this.current_field.description
        }
      })
      .then((res) => {
        if (res.status === 200) this.notifySuccess("the partner field was sucessfully updated.");
      })
      .catch((error) => this.notifyError(error.message))
      .finally(() => this.loading = false)
    },
    async fetchDocuments() {
      // partner_document
      this.loading = true;
      await this.$leasingDataProvider.getList('partner_document')
      .then((res) => {
          this.documents = res.data.map(doc => {
            return {
              ...doc,
              key: doc.partner_document_id,
              value: doc.document_name
            };
          });
          
      })
      .catch(() => this.notifyError('Something went wrong, please try again later.'))
      .finally(() => this.loading = false)
    },
    fetchQuextFields() { // Este si funciona
      this.loading = true;
      this.$leasingDataProvider.getList('quext_field')
      .then((res) => {
        if (res.status === 200) {
          this.quext_fields = res.data.map(quext_field => {
            return {
              ...quext_field,
              key: quext_field.quext_field_name,
              value: quext_field.quext_field_name
            }
          });
        }
      })
      .catch(() => {
        this.notifyError('Something went wrong, please try again later')
      })
      .finally(() => this.loading = false)
    },
    deleteTranslation() {
      this.$leasingDataProvider.delete('field_translation', {
        partner_id:  this.current_field.partner_id,
        partner_field_name: this.current_field.partner_field_name,
        quext_field_name: this.current_field.quext_field_name
      })
      .then(() => {
          this.notifySuccess("the translation field was sucessfully deleted.");
          this.cancel();
      })
      .catch(() => this.notifyError('Something went wrong, please try again later.'))
      .finally(() => this.loading = false);
    },
    deleteField() {
      this.$leasingDataProvider.delete('partner_field', {
        partner_id:  this.current_field.partner_id,
        partner_field_name: this.current_field.partner_field_name,
      })
      .then(() => {
          this.notifySuccess("the partner field was sucessfully deleted.");
          this.cancel();
      })
      .catch(() => this.notifyError('Something went wrong, please try again later.'))
      .finally(() => this.loading = false);
    },
    updateFieldTranslation() {
      this.loading = true;
      this.$leasingDataProvider.update('field_translation', {
        partner_id: this.current_field.partner_id,
        partner_field_name: this.current_field.partner_field_name,
        quext_field_name: this.current_field.quext_field_name,
        data: {
          partner_id: this.current_field.partner_id,
          partner_field_name: this.current_field.partner_field_name,
          quext_field_name: this.current_field.quext_field_name,
          description: this.current_field.field_translation_description,
          partner_field_data_type: this.current_field.partner_field_data_type,
          quext_field_data_type: this.current_field.quext_field_data_type,
          partner_json_layer: this.current_field.partner_json_layer,
          remove_char_regx: this.current_field.remove_char_regx
        }})
      .then(() => {
          this.notifySuccess("the translation field was sucessfully updated.");
      })
      .catch(() => {
        throw new Error('Something went wrong aupdating the field translation, please try again later.');
      })
      .finally(() => this.loading = false)
    },
    saveFieldTranslation() {
      this.loading = true;
      this.$leasingDataProvider.create('field_translation', {data: {
          partner_id: this.current_field.partner_id,
          partner_field_name: this.current_field.partner_field_name,
          quext_field_name: this.current_field.quext_field_name,
          description: this.current_field.field_translation_description,
          partner_field_data_type: this.current_field.partner_field_data_type,
          quext_field_data_type: this.current_field.quext_field_data_type,
          partner_json_layer: this.current_field.partner_json_layer,
          remove_char_regx: this.current_field.remove_char_regx
        }})
        .then(() => {
          this.notifySuccess("the translation field was sucessfully created.");
        })
        .catch(() => {
          throw new Error('Something went wrong creating the field translation, please try again later.');
        })
        .finally(() => this.loading = false)
    },
  },
  beforeMount() {
    this.fetchDocuments();
    this.fetchQuextFields();
    this.current_field = this.getPartnerField;
  },
  created() {
    // delete-field-translation
    EventBus.on('confirm-delete-field-translation', () => {
      this.deleteTranslation();
    })
    EventBus.on('confirm-delete-field-all', () => {
      this.deleteField();
    })
    EventBus.on('delete-field-document', () => {
      this.isSelectDocOpen = true;
    })
    EventBus.on('confirm-delete-partner-field-document', () => {
      this.onDeletefromDoc();
    })
  }
}
</script>